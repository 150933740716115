import 'react-medium-image-zoom/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'components/dist/style.css';
import './global.css';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script'
import type { FC } from 'react';
import React from 'react';
import { ONE_TRUST_PROFILE_ID } from 'src/constants/secrets';
import { isProductionDomain } from 'src/utils/is-production-domain';
import { getAssetPath } from 'src/utils/url/get-asset-path';

const AppProvider = dynamic(() => import('src/providers/app-provider').then(module => module.AppProvider), { ssr: false });

type EnhancedAppProps = AppProps & {
  Component: NextPage;
}

const App: FC<EnhancedAppProps> = (props) => {
  const { Component, pageProps } = props;
  const getLayout = Component.getLayout ?? ((page) => page);

  return (<>
    {/* <Script
      id="PDFNetCWasmwasm"
      src={getAssetPath('static/webviewer10/core/pdf/full/optimized/PDFNetCWasm.br.wasm')}
      strategy="lazyOnload" />
    <Script
      id="PDFNetCWasmmem"
      src={getAssetPath('static/webviewer10/core/pdf/full/PDFNetCWasm.br.js.mem')}
      strategy="lazyOnload" />
    <Script
      id="PDFworker"
      src={getAssetPath('static/webviewer10/core/pdf/PDFworker.js?isfull=true&disableLogs=1&wasm=1')}
      strategy="lazyOnload" /> 
    <Script
      id="pdfnet"
      src={getAssetPath('static/webviewer10/core/pdf/pdfnet.res')}
      strategy="lazyOnload" />
    <Script
      id='webviewer-core'
      src={getAssetPath('static/webviewer10/core/webviewer-core.min.js')}
      strategy="lazyOnload" /> */}
    {/* <!-- OneTrust Cookies Consent Notice start for mysherpas.ai -->*/}
    {isProductionDomain() && (<>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        strategy="lazyOnload"
        data-domain-script={ONE_TRUST_PROFILE_ID} />
      <Script
        id="onetrust"
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper() { }`,
        }} />
    </>)}
    <Head>
      <title>
        mysherpas
      </title>
      <meta
        name="viewport"
        content="initial-scale=1, width=device-width"
      />
    </Head>
    {/* @ts-expect-error */}
    <AppProvider>
      {/* @ts-expect-error */}
      {getLayout(<Component {...pageProps} />)}
    </AppProvider>
  </>
  );
};

export default App;